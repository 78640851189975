import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  VStack,
  Heading,
  Link
} from "@chakra-ui/react";

import { FaPhone, FaEnvelope, FaHome } from "react-icons/fa";

import { useContext } from "react";
import { TranslatedTextContext } from "../App.js";

export default function Contact({ color }) {
  const translatedText = useContext(TranslatedTextContext);

  return (
    <>
      <Container maxW={"3xl"} id="contact">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="right" direction="row" p={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                04
              </Text>
              <Text fontWeight={800}>{translatedText.contact.name}</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
            <Heading fontSize={"3xl"}>{translatedText.contact.stayInTouch}</Heading>
            <VStack pt={4} spacing={4} >
              <HStack><FaPhone size={28} />
                <Text>051-8314981</Text></HStack><HStack><FaHome size={28} />
                <Text>{translatedText.contact.address}</Text></HStack>
              <HStack>

                <FaEnvelope size={28} />
                <Link href="mailto:alfawzacademy786@gmail.com">
                  alfawzacademy786@gmail.com
                </Link></HStack>
            </VStack>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}

