import React from 'react';
import './App.css';
import Nav from './components/NavBar';
import Header from './components/Hero';
import About from './components/About';
import Experience from './components/Experience';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { createContext, useState } from "react";
import translationData from "./translation.json";

export const TranslatedTextContext = createContext(null);

function App() {
  // Available Colours:
  // blue, cyan, gray, green, orange, pink, purple, red, teal, yellow

  // edit this variable to change the color theme
  const color = "teal";
  const [language, setLanguage] = useState("en");

  return (
    <>
      <TranslatedTextContext.Provider
        value={language === "en" ? translationData.en : translationData.ur}
      >
        <Nav color={color} setLanguage={setLanguage} />
        <Header color={color} />
        <About color={color} />
        <Experience color={color} />
        <Projects color={color} />
        <Contact color={color} />
        <Footer />
      </TranslatedTextContext.Provider>
    </>
  );
}

export default App;
