import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
} from "@chakra-ui/react";

import { useContext } from "react";
import { TranslatedTextContext } from "../App.js";

export default function Projects({ color }) {
  const translatedText = useContext(TranslatedTextContext);

  return (
    <>
      <Container maxW={"3xl"} id="projects">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" p={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                03
              </Text>
              <Text fontWeight={800}>{
                translatedText.downloads.name}</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <iframe
            title='downloads'
            src="https://drive.google.com/embeddedfolderview?id=1JSxYv3Eag0_qE5XA4Uvsv0HTqAUq5VbD"
            width="100%"
            height="500px"
          />
        </Stack>
      </Container>
    </>
  );
}
