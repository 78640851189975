import {
  Divider,
  Stack,
  Text,
  Container,
  Box,
  HStack,
  SimpleGrid,
  Heading,
  Card,
  CardBody,
} from "@chakra-ui/react";

import { useContext } from "react";
import { TranslatedTextContext } from "../App.js";

export default function Experience({ color }) {
  const translatedText = useContext(TranslatedTextContext);

  const team = [
    translatedText.team.member1,
    translatedText.team.member2,
    translatedText.team.member3,
    translatedText.team.member4,
    translatedText.team.member5,
    translatedText.team.member6,
    translatedText.team.member7,]
  return (
    <>
      <Container maxW={"3xl"} id="experience">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" px={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                02
              </Text>
              <Text fontWeight={800}>{translatedText.team.name}</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>

          <SimpleGrid columns={[1, 2, 3]} px={4} spacing={4}>
            {team.map(member => <Card >
              <Stack>
                <CardBody align="left" h={[null, "40vh"]}>
                  <Heading size="sm">{member.name}</Heading>

                  <Text fontSize="sm" py={2}>
                    {member.designation}
                  </Text>

                </CardBody>
              </Stack>
            </Card>)}
          </SimpleGrid>

        </Stack>
      </Container>
    </>
  );
}
